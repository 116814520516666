import vi from 'vuetify/lib/locale/vi'

const nav = {
	"Home": "Nhà",
	"User Profile": "Hồ sơ người dùng",
	"Starter Pack": "Gói khởi động",
	"Features": "Hàm số",
	"Options": "Tùy chọn",
	"Language Switcher": "Trình chuyển đổi ngôn ngữ",
	"Log Out": "Đăng xuất",
}

const token_transaction = {
	"Token Transactions": "Giao dịch token",
	"Type": "Kiểu",
	"Token Type": "Loại token",
	"Amount": "Lượng",
	"Created at": "Được tạo tại",
}

const starter_pack = {
	"NFT SHOE": "Giày NFT",
	"Mint": "Như",
	"Walker": "Walker",
	"Jogger": "Người chạy bộ",
	"Runner": "Runner",
	"Selected": "Chọn",
	"Total": "Tất cả",
	"Back": "Lưng",
	"This feature is currently unavailable.": "Tính năng này hiện không khả dụng."
}

const marketplace = {
	"Marketplace": "Marketplace",
	"Sneaker": "Sneakers",
	"Shoebox": "Hộp đựng giày",
	"Artifact": "Artifacts",
	"Sort By": "Sắp xếp theo By",
	"Latest": "Đặt",
	"Oldest": "Cũ nhất",
	"Rarity": "Hiếm",
	"Level": "Mức",
	"Stage": "Sân khấu",
	"Star": "Sao",
	"Buy": "Mua",
	"Type": "Kiểu",
	"Attribute": "Thuộc tính",
	"Your Item": "Mặt hàng của bạn",
	"Price": "Giá bán",
	"Effect": "Hiệu ứng",
	"Scroll Up To Load More": "Cuộn lên để tải thêm",
	"No Item Found": "Không tìm thấy mục nào",
	"End of Data": "Kết thúc dữ liệu",
	"All": "Tất cả các",
	"Condition": "Điều kiện",
	"Minting": "Đúc",
	"Attributes": "Thuộc tính",
	"Available Point": "Điểm có sẵn",
	"Base": "Căn cứ",
	"Add": "Thêm",
	"Efficiency": "Hiệu quả",
	"Luck": "Vận",
	"Firmness": "Cứng",
	"Durability": "Độ bền",
}

const faq = {
	"How to Play": "Cách chơi",
	"What is Starter Pack": "Starter Pack là gì",
	"What is Marketplace (Coming Soon)": "Marketplace là gì (sắp ra mắt)",
	"What is Marketplace": "Marketplace là gì",
	"White Paper": "Trang trắng",
	"Coming Soon": "Sắp ra mắt",
}

const user = {
	"Email": "Email",
	"Ranking": "Xếp hạng",
	"Left Side Pairing Amount": "Số tiền ghép nối bên trái",
	"Right Side Pairing Amount": "Số tiền ghép nối bên phải",
	"Referred By": "Được giới thiệu bởi",
	"Joined at": "Tham gia tại",
	"Status": "Tình trạng",
	"Active": "Hoạt động",
	"Deactivated": "Đã hủy kích hoạt",
	"Uncalculated": "Chưa tính toán",
	"Pending": "Đang chờ xử lý",
}

const misc = {
	"Filters": "Bộ lọc",
	"Type": "Kiểu",
	"Apply": "Áp dụng",
}

export default {
	$vuetify: {
		...vi
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}