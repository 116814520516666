import axios from 'axios';

let prefix = "/sneaker-type"

const SneakerTypeApi = {
	
	getSneakerTypeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default SneakerTypeApi;