import axios from 'axios';

let prefix = "/gem-type"

const GemTypeApi = {
	
	getGemTypeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default GemTypeApi;