import axios from 'axios';

let prefix = "/gem-star"

const GemStarApi = {
	getGemStarList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getGemStageList(payload){
		return axios.get( prefix + "/stage-list", {params: payload})
	},
}

export default GemStarApi;