import axios from 'axios';

let prefix = "/direct-sponsor"

const DirectSponsorApi = {
	getDirectSponsorList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default DirectSponsorApi;