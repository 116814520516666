import zhHans from 'vuetify/lib/locale/zh-Hans'

const nav = {
	"Home": "家",
	"User Profile": "用户配置文件",
	"Starter Pack": "入门包",
	"Features": "功能",
	"Options": "选项",
	"Language Switcher": "语言切换器",
	"Log Out": "注销",
}

const token_transaction = {
	"Token Transactions": "代币交易",
	"Type": "类型",
	"Token Type": "令牌类型",
	"Amount": "数量",
	"Created at": "创建于",
}

const starter_pack = {
	"NFT SHOE": "NFT 鞋",
	"Mint": "铸造",
	"Walker": "行走者",
	"Jogger": "慢跑者",
	"Runner": "跑步者",
	"Selected": "选择",
	"Total": "总",
	"Back": "返回",
	"This feature is currently unavailable.": "此功能目前无法使用."
}

const marketplace = {
	"Marketplace": "市场",
	"Sneaker": "球鞋",
	"Shoebox": "鞋盒",
	"Artifact": "神器",
	"Sort By": "排序方式",
	"Latest": "最近的",
	"Oldest": "古老",
	"Rarity": "稀罕",
	"Level": "水平",
	"Stage": "阶段",
	"Star": "星",
	"Buy": "购买",
	"Type": "类型",
	"Attribute": "属性",
	"Your Item": "您的项目",
	"Price": "价格",
	"Effect": "效果",
	"Scroll Up To Load More": "向上滚动以加载更多",
	"No Item Found": "未找到项目",
	"End of Data": "数据结束",
	"All": "全部",
	"Condition": "条件",
	"Minting": "铸币",
	"Attributes": "属性",
	"Available Point": "可用积分",
	"Base": "基础",
	"Add": "加",
	"Efficiency": "效率",
	"Luck": "运气",
	"Firmness": "坚定",
	"Durability": "耐久性",
}

const faq = {
	"How to Play": "怎么玩？",
	"What is Starter Pack": "什么是入门包",
	"What is Marketplace (Coming Soon)": "什么是 市场（即将推出）",
	"What is Marketplace": "什么是 市场",
	"White Paper": "白皮书",
	"Coming Soon": "即将推出",
}

const user = {
	"Email": "电子邮件",
	"Ranking": "排名",
	"Left Side Pairing Amount": "左侧配对量",
	"Right Side Pairing Amount": "右侧配对金额",
	"Referred By": "推荐人",
	"Joined at": "加入于",
	"Status": "地位",
	"Active": "积极",
	"Deactivated": "停用",
	"Uncalculated": "未计算",
	"Pending": "待定",
}

const misc = {
	"Filters": "过滤",
	"Type": "类型",
	"Apply": "应用",
}

export default {
	$vuetify: {
		...zhHans
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}