import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '@/locales/zh'
import en from '@/locales/en'
import ko from '@/locales/ko'
import vi from '@/locales/vi'
import ja from '@/locales/ja'
import ru from '@/locales/ru'
import id from '@/locales/id'
import es from '@/locales/es'
import pt from '@/locales/pt'
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify';
Vue.use(Vuetify);
Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: 'zh', // set locale
	fallbackLocale: 'en',
	messages: {
		zh, en, ko, vi, ja, ru, id, es, pt
	}, // set locale messages
	silentTranslationWarn: true
})

  
export default {
	vuetify: new Vuetify({
		i18n,
		theme: {
			dark : false,
			themes: {
				light: {
					primary: '#F28C19',
					secondary: '#FFFFFF',
					'tertiary' : '#3EA6FF',
					'background': '#FFFFFF',
					'opposite': '#202020',
				},
				dark: {
					primary: '#F28C19',
					secondary: '#202020',
					'tertiary' : '#3EA6FF',
					'background': '#181818',
					'opposite': '#FFFFFF',
				},
			},
		},
		lang: {
			t: (key, ...params) => {
				if(!key.includes("$vuetify."))
					key = "$vuetify." + key
				return i18n.t(key, params)
			},
		},
	}),
	i18n
}