import ja from 'vuetify/lib/locale/ja'

const nav = {
	"Home": "家",
	"User Profile": "ユーザープロフィール",
	"Starter Pack": "スターターパック",
	"Features": "機能",
	"Options": "オプション",
	"Language Switcher": "言語スイッチャー",
	"Log Out": "ログアウトする",
}

const token_transaction = {
	"Token Transactions": "トークントランザクション",
	"Type": "種類",
	"Token Type": "トークンの種類",
	"Amount": "量",
	"Created at": "作成日時",
}

const starter_pack = {
	"NFT SHOE": "NFTシューズ",
	"Mint": "として",
	"Walker": "歩行器",
	"Jogger": "ジョガー",
	"Runner": "ランナー",
	"Selected": "選択",
	"Total": "トータル",
	"Back": "戻る",
	"This feature is currently unavailable.": "この機能は現在利用できません"
}

const marketplace = {
	"Marketplace": "市場",
	"Sneaker": "スニーカー",
	"Shoebox": "靴箱",
	"Artifact": "成果 物",
	"Sort By": "並べ替え",
	"Latest": "最新",
	"Oldest": "最古",
	"Rarity": "暁星",
	"Level": "レベル",
	"Stage": "舞台",
	"Star": "星",
	"Buy": "買う",
	"Type": "種類",
	"Attribute": "属性",
	"Your Item": "あなたのアイテム",
	"Price": "価格",
	"Effect": "効果",
	"Scroll Up To Load More": "上にスクロールしてさらに読み込む",
	"No Item Found": "アイテムが見つかりません",
	"End of Data": "データの終わり",
	"All": "全て",
	"Condition": "条件",
	"Minting": "鋳造",
	"Attributes": "属性",
	"Available Point": "利用可能ポイント",
	"Base": "基",
	"Add": "足す",
	"Efficiency": "効率",
	"Luck": "運",
	"Firmness": "強固",
	"Durability": "耐久性",
}

const faq = {
	"How to Play": "遊び方は?",
	"What is Starter Pack": "スターターパックとは",
	"What is Marketplace (Coming Soon)": "マーケットプレイスとは (近日公開予定)",
	"What is Marketplace": "マーケットプレイスとは",
	"White Paper": "白紙",
	"Coming Soon": "近日公開予定",
}

const user = {
	"Email": "電子メール",
	"Ranking": "ランキング",
	"Left Side Pairing Amount": "左サイドペアリング量",
	"Right Side Pairing Amount": "右側のペアリング量",
	"Referred By": "参照元",
	"Joined at": "で参加",
	"Status": "地位",
	"Active": "能動",
	"Deactivated": "非活動化",
	"Uncalculated": "未計算",
	"Pending": "保留中",
}

const misc = {
	"Filters": "フィルター",
	"Type": "種類",
	"Apply": "申し込む",
}

export default {
	$vuetify: {
		...ja
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}