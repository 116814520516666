import axios from 'axios';

let prefix = "/marketplace"

const MarketplaceApi = {
	
	getMarketplaceFee(payload){
		return axios.get( prefix + "/get-fee", {params: payload})
	},
	getMarketplaceList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getMarketplaceInfo(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	buyItem(payload){
		return axios.post( prefix + "/buy", payload)
	},
}

export default MarketplaceApi;