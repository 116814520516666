import es from 'vuetify/lib/locale/es'

const nav = {
	"Home": "Hogar",
	"User Profile": "Perfil de usuario",
	"Starter Pack": "Paquete de inicio",
	"Features": "Opciones",
	"Options": "Opciones",
	"Language Switcher": "Cambiar idioma",
	"Log Out": "Cerrar sesión",
}

const token_transaction = {
	"Token Transactions": "Transacción de tokens",
	"Type": "Tipo",
	"Token Type": "Tipo de token",
	"Amount": "Importe",
	"Created at": "Creado en",
}

const starter_pack = {
	"NFT SHOE": "Zapato NFT",
	"Mint": "Como",
	"Walker": "Caminante",
	"Jogger": "Mono",
	"Runner": "Corredor",
	"Selected": "Seleccionar/Seleccionar",
	"Total": "Total",
	"Back": "Atrás",
	"This feature is currently unavailable.": "La función para comprar el paquete de inicio no está disponible actualmente. Disculpen las molestias causadas."
}

const marketplace = {
	"Marketplace": "Marketplace",
	"Sneaker": "Zapatos deportivos",
	"Shoebox": "Cajas de zapatos",
	"Artifact": "Artefactos",
	"Sort By": "Ordenar por",
	"Latest": "Último",
	"Oldest": "Mayor",
	"Rarity": "Rareza",
	"Level": "Nivel",
	"Stage": "Etapa",
	"Star": "Estrella",
	"Buy": "Comprar",
	"Type": "Tipo",
	"Attribute": "Atributos",
	"Your Item": "Su artículo",
	"Price": "Precio de listado",
	"Effect": "Efecto",
	"Scroll Up To Load More": "Desplácese hacia arriba para cargar más",
	"No Item Found": "No se encontró ningún artículo",
	"End of Data": "Fin de datos",
	"All": "Todos",
	"Condition": "Condición",
	"Minting": "Acuñación",
	"Attributes": "Atributos",
	"Available Point": "Puntos Disponibles",
	"Base": "Base",
	"Add": "Agregar",
	"Efficiency": "Eficacia",
	"Luck": "Suerte",
	"Firmness": "Firmeza",
	"Durability": "Durabilidad",
}

const faq = {
	"How to Play": "¿Cómo jugar??",
	"What is Starter Pack": "Qué es un paquete de inicio?",
	"What is Marketplace (Coming Soon)": "Qué es Marketplace (próximamente)",
	"What is Marketplace": "Qué es Marketplace",
	"White Paper": "papel blanco",
	"Coming Soon": "próximamente",
}

const user = {
	"Email": "Correo electrónico",
	"Ranking": "Clasificación",
	"Left Side Pairing Amount": "Cantidad de emparejamiento del lado izquierdo",
	"Right Side Pairing Amount": "Cantidad de emparejamiento del lado derecho",
	"Referred By": "Referido por",
	"Joined at": "Se unió en",
	"Status": "Estado",
	"Active": "Activo",
	"Deactivated": "Desactivó",
	"Uncalculated": "sin calcular",
	"Pending": "pendiente",
}

const misc = {
	"Filters": "Filtros",
	"Apply": "Aplicar",
}


export default {
	$vuetify: {
		...es
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}