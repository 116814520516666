import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import( '@/views/_general/Comingsoon')
const Error404 = () => import( '@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import( '@/views/_general/Login')
const ResetPassword = () => import('@/views/_general/ResetPassword')
const FaqPage = () => import('@/views/_general/FaqPage')

const Home = () => import( '@/views/_general/Home')

const UserProfile = () => import( '@/views/Users/UserProfile')

const MintingPackPage = () => import( '@/views/MintingPacks/MintingPackPage')
const MarketplacePage = () => import( '@/views/Marketplaces/MarketplacePage')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/marketplace', name: 'marketplace.page', component: MarketplacePage, meta: { auth: true, title: 'Marketplace', has_minting: true, exclusive_user: false } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },

	{ path: '/user/profile', name: 'user.profile', component: UserProfile, meta: { auth: true, title: 'User Profile', has_minting: true, exclusive_user: false } },
	
	{ path: '/starter-pack', name: 'starter-pack.page', component: MintingPackPage, meta: { auth: true, title: 'Starter Pack', exclusive_user: false } },


]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },
	{ path: '/faq', name: 'faq.page', component: FaqPage, meta: { auth: null, title: 'Faq', has_minting: false } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	next()
});

export default router