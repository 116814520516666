import pt from 'vuetify/lib/locale/pt'

const nav = {
	"Home": "Casa",
	"User Profile": "Perfil do usuário",
	"Starter Pack": "Pacote de partida",
	"Features": "Opções",
	"Options": "Opções",
	"Language Switcher": "Alterar Idioma",
	"Log Out": "Log Out",
}

const token_transaction = {
	"Token Transactions": "Transação de Token",
	"Type": "Tipo",
	"Token Type": "Tipo de token",
	"Amount": "Quantidade",
	"Created at": "Criado em",
}

const starter_pack = {
	"NFT SHOE": "Sapato NFT",
	"Mint": "Como",
	"Walker": "Andador",
	"Runner": "Corredor",
	"Jogger": "Agasalho esportivo",
	"Selected": "Selecionar/selecionar",
	"Total": "Total",
	"Back": "Voltar",
	"This feature is currently unavailable.": "O recurso para comprar pacote inicial está atualmente indisponível. Desculpe pelo inconveniente causado."
}

const marketplace = {
	"Marketplace": "Marketplace",
	"Sneaker": "Tênis",
	"Shoebox": "Caixas de sapatos",
	"Artifact": "Artefatos",
	"Sort By": "Classificar por",
	"Latest": "Mais recente",
	"Oldest": "Mais",
	"Rarity": "Raridade",
	"Level": "Nível",
	"Stage": "Palco",
	"Star": "Estrela",
	"Buy": "Comprar",
	"Type": "Tipo",
	"Attribute": "Atributos",
	"Your Item": "Seu item",
	"Price": "Preço de listagem",
	"Effect": "Efeito",
	"Scroll Up To Load More": "Role para cima para carregar mais",
	"No Item Found": "Nenhum item encontrado",
	"End of Data": "Fim dos dados",
	"All": "Tudo",
	"Condition": "Condição",
	"Minting": "Cunhagem",
	"Attributes": "Atributos",
	"Available Point": "Pontos Disponíveis",
	"Base": "Base",
	"Add": "Adicionar",
	"Efficiency": "Eficiência",
	"Luck": "Sorte",
	"Firmness": "Firmeza",
	"Durability": "Durabilidade",
}

const faq = {
	"How to Play": "Como jogar??",
	"What is Starter Pack": "que é um pacote inicial",
	"What is Marketplace (Coming Soon)": "que é Marketplace (em breve)",
	"What is Marketplace": "que é Marketplace",
	"White Paper": "papel branco",
	"Coming Soon": "em breve",
}

const user = {
	"Email": "Email",
	"Ranking": "Classificação",
	"Left Side Pairing Amount": "Quantidade de pareamento do lado esquerdo",
	"Right Side Pairing Amount": "Quantidade de emparelhamento do lado direito",
	"Referred By": "Encaminhado por",
	"Joined at": "Juntou-se em",
	"Status": "Estado",
	"Active": "Ativo",
	"Deactivated": "Desativou",
	"Uncalculated": "não calculada",
	"Pending": "pendente",
}

const misc = {
	"Filters": "Filtros",
	"Apply": "Aplicar",
}


export default {
	$vuetify: {
		...pt
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}