import axios from 'axios';

let prefix = "/auth"

const AuthApi = {
	resetPassword(payload){
		return axios.post( prefix + "/verify/reset-password", payload)
	},
	requestOtp(payload){
		return axios.post( prefix + "/request-otp", payload)
	},
	forgotPassword(payload){
		return axios.post( prefix + "/reset-password", payload)
	},
	otpUpdatePassword(payload){
		return axios.post( prefix + "/update-password", payload)
	},
	getLanguageList(payload){
		return axios.get( prefix + "/language/list", {params: payload})
	},
	updateLanguage(payload){
		return axios.post( prefix + "/update-language", payload)
	},
}

export default AuthApi;