import en from 'vuetify/lib/locale/en'

const nav = {
	"Home": "Home",
	"User Profile": "User Profile",
	"Starter Pack": "Starter Pack",
	"Features": "Features",
	"Options": "Options",
	"Language Switcher": "Language Switcher",
	"Log Out": "Log Out",
}

const token_transaction = {
	"Token Transactions": "Token Transactions",
	"Type": "Type",
	"Token Type": "Token Type",
	"Amount": "Amount",
	"Created at": "Created at",
}

const starter_pack = {
	"NFT SHOE": "NFT SHOE",
	"Mint": "Mint",
	"Walker": "Walker",
	"Jogger": "Jogger",
	"Runner": "Runner",
	"Selected": "Selected",
	"Total": "Total",
	"Back": "Back",
	"This feature is currently unavailable.": "This feature is currently unavailable."
}

const marketplace = {
	"Marketplace": "Marketplace",
	"Sneaker": "Sneaker",
	"Shoebox": "Shoebox",
	"Artifact": "Artifact",
	"Sort By": "Sort By",
	"Latest": "Latest",
	"Oldest": "Oldest",
	"Rarity": "Rarity",
	"Level": "Level",
	"Stage": "Stage",
	"Star": "Star",
	"Buy": "Buy",
	"Type": "Type",
	"Attribute": "Attribute",
	"Your Item": "Your Item",
	"Price": "Price",
	"Effect": "Effect",
	"Scroll Up To Load More": "Scroll Up To Load More",
	"No Item Found": "No Item Found",
	"End of Data": "End of Data",
	"All": "All",
	"Condition": "Condition",
	"Minting": "Minting",
	"Attributes": "Attributes",
	"Available Point": "Available Point",
	"Base": "Base",
	"Add": "Add",
	"Efficiency": "Efficiency",
	"Luck": "Luck",
	"Firmness": "Firmness",
	"Durability": "Durability",
}

const faq = {
	"How to Play": "How to Play",
	"What is Starter Pack": "What is Starter Pack",
	"What is Marketplace (Coming Soon)": "What is Marketplace (Coming Soon)",
	"What is Marketplace": "What is Marketplace",
	"Jogger": "Jogger",
	"White Paper": "White Paper",
	"Coming Soon": "Coming Soon",
}

const user = {
	"Email": "Email",
	"Ranking": "Ranking",
	"Left Side Pairing Amount": "Left side Pairing Amount",
	"Right Side Pairing Amount": "Right side Pairing Amount",
	"Referred By": "Referred By",
	"Joined at": "Joined at",
	"Status": "Status",
	"Active": "Active",
	"Deactivated": "Deactivated",
	"Uncalculated": "Uncalculated",
	"Pending": "Pending",
}

const misc = {
	"Filters": "Filters",
	"Type": "Type",
	"Apply": "Apply",
}

export default {
	$vuetify: {
		...en
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}