import axios from 'axios';

let prefix = "/sneaker-rarity"

const SneakerRarityApi = {
	
	getSneakerRarityList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default SneakerRarityApi;