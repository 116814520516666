import id from 'vuetify/lib/locale/id'

const nav = {
	"Home": "Rumah",
	"User Profile": "Profil Pengguna",
	"Starter Pack": "paket permula",
	"Features": "Pilihan",
	"Options": "Pilihan",
	"Language Switcher": "Ganti Bahasa",
	"Log Out": "Log Out",
}

const token_transaction = {
	"Token Transactions": "Transaksi Token",
	"Type": "Jenis",
	"Token Type": "Jenis Token",
	"Amount": "Jumlah",
	"Created at": "Dibuat di",
}

const starter_pack = {
	"NFT SHOE": "Sepatu NFT",
	"Mint": "Sebagai",
	"Walker": "Walker",
	"Jogger": "Pelari",
	"Runner": "Pelari",
	"Selected": "Pilih/Pilih",
	"Total": "Seluruh",
	"Back": "Belakang",
	"This feature is currently unavailable.": "Fitur pembelian paket permula saat ini tidak tersedia. Maaf atas ketidaknyamanan yang ditimbulkan."
}

const marketplace = {
	"Marketplace": "Marketplace",
	"Sneaker": "Sepatu kets",
	"Shoebox": "Kotak sepatu",
	"Artifact": "Artefak 物",
	"Sort By": "Urutkan berdasarkan",
	"Latest": "Terbaru",
	"Oldest": "Sulung",
	"Rarity": "Kelangkaan",
	"Level": "Tingkat",
	"Stage": "Panggung",
	"Star": "Bintang",
	"Buy": "Membeli",
	"Type": "Jenis",
	"Attribute": "Atribut",
	"Your Item": "Barang Anda",
	"Price": "Harga Listing",
	"Effect": "Memengaruhi",
	"Scroll Up To Load More": "Gulir Ke Atas Untuk Memuat Lebih Banyak",
	"No Item Found": "Tidak Ada Barang yang Ditemukan",
	"End of Data": "Akhir Data",
	"All": "Semua",
	"Condition": "Keadaan",
	"Minting": "Mencetak",
	"Attributes": "Atribut",
	"Available Point": "Poin yang Tersedia",
	"Base": "Dasar",
	"Add": "Tambah",
	"Efficiency": "Efisiensi",
	"Luck": "Keberuntungan",
	"Firmness": "Ketegasan",
	"Durability": "Daya tahan",
}

const faq = {
	"How to Play": "Bagaimana cara bermainnya??",
	"What is Starter Pack": "Apa itu paket pemula?",
	"What is Marketplace (Coming Soon)": "Apa itu Marketplace (segera hadir)",
	"What is Marketplace": "Apa itu Marketplace",
	"White Paper": "Kertas putih",
	"Coming Soon": "segera hadir",
}

const user = {
	"Email": "Email",
	"Ranking": "Peringkat",
	"Left Side Pairing Amount": "Jumlah Pasangan Sisi Kiri",
	"Right Side Pairing Amount": "Jumlah Pasangan Sisi Kanan",
	"Referred By": "Dirujuk Oleh",
	"Joined at": "Bergabung di",
	"Status": "Keadaan",
	"Active": "Aktif",
	"Deactivated": "Dinonaktifkan",
	"Uncalculated": "Tidak Dihitung",
	"Pending": "Tertunda",
}

const misc = {
	"Filters": "Filter",
	"Apply": "Berlaku",
}

export default {
	$vuetify: {
		...id
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}