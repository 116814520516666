import ko from 'vuetify/lib/locale/ko'

const nav = {
	"Home": "집",
	"User Profile": "사용자 프로필",
	"Starter Pack": "스타터 팩",
	"Features": "기능",
	"Options": "옵션",
	"Language Switcher": "언어 전환기",
	"Log Out": "로그아웃",
}

const token_transaction = {
	"Token Transactions": "토큰 트랜잭션",
	"Type": "형",
	"Token Type": "토큰 유형",
	"Amount": "분량",
	"Created at": "만든 위치",
}

const starter_pack = {
	"NFT SHOE": "NFT 슈즈",
	"Mint": "만큼",
	"Walker": "보행자",
	"Jogger": "조깅",
	"Runner": "러너",
	"Selected": "선택",
	"Total": "합계",
	"Back": "뒤로",
	"This feature is currently unavailable.": "이 기능은 현재 사용할 수 없습니다"
}

const marketplace = {
	"Marketplace": "시장",
	"Sneaker": "운동화",
	"Shoebox": "신발 상자",
	"Artifact": "아티팩트",
	"Sort By": "정렬 기준",
	"Latest": "최근",
	"Oldest": "오래 된",
	"Rarity": "진귀",
	"Level": "수준",
	"Stage": "무대",
	"Star": "별",
	"Buy": "사다",
	"Type": "형",
	"Attribute": "특성",
	"Your Item": "귀하의 아이템",
	"Price": "가격",
	"Effect": "효과",
	"Scroll Up To Load More": "더 로드하려면 위로 스크롤",
	"No Item Found": "항목을 찾을 수 없음",
	"End of Data": "데이터 끝",
	"All": "모두",
	"Condition": "조건",
	"Minting": "채광",
	"Attributes": "특성",
	"Available Point": "사용 가능한 포인트",
	"Base": "기지",
	"Add": "더하다",
	"Efficiency": "능률",
	"Luck": "운",
	"Firmness": "견고",
	"Durability": "내구성",
}

const faq = {
	"How to Play": "게임 방법?",
	"What is Starter Pack": "스타터 팩이란 무엇입니까?",
	"What is Marketplace (Coming Soon)": "마켓플레이스란 무엇인가 (출시 예정)",
	"What is Marketplace": "마켓플레이스란 무엇인가",
	"White Paper": "백서",
	"Coming Soon": "출시 예정",
}

const user = {
	"Email": "이메일",
	"Ranking": "순위",
	"Left Side Pairing Amount": "왼쪽 페어링 양",
	"Right Side Pairing Amount": "오른쪽 페어링 양",
	"Referred By": "추천인",
	"Joined at": "에 가입",
	"Status": "상태",
	"Active": "활동적인",
	"Deactivated": "비활성화됨",
	"Uncalculated": "계산되지 않음",
	"Pending": "보류 중",
}

const misc = {
	"Filters": "필터",
	"Type": "형",
	"Apply": "적용하다",
}

export default {
	$vuetify: {
		...ko
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}