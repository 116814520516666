import ru from 'vuetify/lib/locale/ru'

const nav = {
	"Home": "Дом",
	"User Profile": "Профиль пользователя",
	"Starter Pack": "Стартовый пакет",
	"Features": "Функция",
	"Options": "Параметры",
	"Language Switcher": "Переключатель языков",
	"Log Out": "Выходить",
}

const token_transaction = {
	"Token Transactions": "Транзакция токена",
	"Type": "Тип",
	"Token Type": "Тип токена",
	"Amount": "Количество",
	"Created at": "Создано в",
}

const starter_pack = {
	"NFT SHOE": "Обувь NFT",
	"Mint": "Как",
	"Walker": "Ходок",
	"Jogger": "Бегун",
	"Runner": "Бегун",
	"Selected": "Выбрать",
	"Total": "Итог",
	"Back": "Назад",
	"This feature is currently unavailable.": "Эта функция в данный момент недоступна."
}

const marketplace = {
	"Marketplace": "Торговая площадка",
	"Sneaker": "Кроссовки",
	"Shoebox": "Обувные коробки",
	"Artifact": "Артефакты",
	"Sort By": "Сортировать по",
	"Latest": "Самый поздний",
	"Oldest": "Старейший",
	"Rarity": "Редкость",
	"Level": "Уровень",
	"Stage": "Сцена",
	"Star": "Звезда",
	"Buy": "Покупать",
	"Type": "Тип",
	"Attribute": "Атрибуты",
	"Your Item": "Ваш товар",
	"Price": "Цена",
	"Effect": "Эффект",
	"Scroll Up To Load More": "Прокрутите вверх, чтобы загрузить больше",
	"No Item Found": "Элемент не найден",
	"End of Data": "Конец данных",
	"All": "Все",
	"Condition": "Состояние",
	"Minting": "Чеканки",
	"Attributes": "Атрибуты",
	"Available Point": "Доступных баллов",
	"Base": "Основа",
	"Add": "Добавлять",
	"Efficiency": "Эффективность",
	"Luck": "Удача",
	"Firmness": "Твёрдость",
	"Durability": "Долговечность",
}

const faq = {
	"How to Play": "Как играть?",
	"What is Starter Pack": "Что такое стартовый пакет",
	"What is Marketplace (Coming Soon)": "Что такое Marketplace (скоро)",
	"What is Marketplace": "Что такое Marketplace",
	"White Paper": "Информационный документ",
	"Coming Soon": "скоро",
}

const user = {
	"Email": "Отправить по электронной почте",
	"Ranking": "Ранжирование",
	"Left Side Pairing Amount": "Сумма сопряжения с левой стороной",
	"Right Side Pairing Amount": "Сумма сопряжения с правой стороной",
	"Referred By": "Рекомендовано",
	"Joined at": "Присоединились к",
	"Status": "Статус",
	"Active": "Активный",
	"Deactivated": "Деактивировано",
	"Uncalculated": "несчитанная",
	"Pending": "в ожидании",
}

const misc = {
	"Filters": "Фильтры",
	"Apply": "Применять",
}

export default {
	$vuetify: {
		...ru
	},
	...nav,
	...starter_pack,
	...marketplace,
	...token_transaction,
	...faq,
	...user,
	...misc
}